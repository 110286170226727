export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'purpose',
    label: 'field.purpose',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: "field.visitCallPlan", value: "1" },
      { text: "field.outletSensor", value: "2" },
      { text: "field.supportSales", value: "3" },
      { text: "field.other", value: "4" },
    ],
  },
  {
    key: 'createdAt',
    label: 'field.date',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
]
