export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'purpose',
    label: 'field.purpose',
    width: 2000,
    options: ["", "purpose.1", "purpose.2", "purpose.3", "purpose.4"],
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    width: 3500,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    width: 6000,
  },
  {
    key: 'supportSalespersonIdCard',
    label: 'field.supportSalespersonIdCard',
  },
  {
    key: 'supportSalespersonName',
    label: 'field.supportSalespersonName',
  },
  {
    key: 'checkinTime',
    label: 'field.checkinTime',
  },
  {
    key: 'checkinImage',
    label: 'field.checkinImage',
    width: 10000,
  },
  {
    key: 'checkinLatitude',
    label: 'field.checkinLatitude',
  },
  {
    key: 'checkinLongitude',
    label: 'field.checkinLongitude',
  },
  {
    key: 'checkinAddress',
    label: 'field.checkinAddress',
    width: 10000,
  },
  {
    key: 'checkoutTime',
    label: 'field.checkoutTime',
  },
  {
    key: 'checkoutImage',
    label: 'field.checkoutImage',
    width: 10000,
  },
  {
    key: 'checkoutAddress',
    label: 'field.checkoutAddress',
    width: 10000,
  },
  {
    key: 'checkoutLatitude',
    label: 'field.checkoutLatitude',
  },
  {
    key: 'checkoutLongitude',
    label: 'field.checkoutLongitude',
  },
  {
    key: 'duration',
    label: 'field.duration',
    width: 2000,
  },
  {
    key: 'distance',
    label: 'field.distance',
  },
  {
    key: 'remark',
    label: 'field.remark',
    width: 10000,
  },
];
